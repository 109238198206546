// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableFixHead {
    overflow: auto;
    height: 100px;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}

.divScroll {
    overflow: auto;
    max-height: 300px;
}

.divTradingView {
    height: 500px;
}

.cardDark{
    background-color: #363c4e;
}`, "",{"version":3,"sources":["webpack://./src/private/Dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,MAAM;IACN,UAAU;AACd;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".tableFixHead {\r\n    overflow: auto;\r\n    height: 100px;\r\n}\r\n\r\n.tableFixHead thead th {\r\n    position: sticky;\r\n    top: 0;\r\n    z-index: 1;\r\n}\r\n\r\n.divScroll {\r\n    overflow: auto;\r\n    max-height: 300px;\r\n}\r\n\r\n.divTradingView {\r\n    height: 500px;\r\n}\r\n\r\n.cardDark{\r\n    background-color: #363c4e;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
