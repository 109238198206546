// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tabContent {
  border-left: 0.0625rem solid #9ca3af;
  border-right: 0.0625rem solid #9ca3af;
  border-bottom: 0.0625rem solid #9ca3af;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.nav-tabs .nav-link {
  margin-bottom: 0px;
}

.input-group .alert {
  font-size: 0.6rem;
}

.divScrollBadges {
  overflow: auto;
  max-height: 100px;
}

.hourPart {
  max-width: 66px;
}

.divAutomationsList {
  overflow: auto;
  max-height: 150px;
}

.divBacktestResults {
  overflow: auto;
  max-height: 350px;
}
`, "",{"version":3,"sources":["webpack://./src/private/Automations/Automations.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,qCAAqC;EACrC,sCAAsC;EACtC,8BAA8B;EAC9B,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":["#tabContent {\r\n  border-left: 0.0625rem solid #9ca3af;\r\n  border-right: 0.0625rem solid #9ca3af;\r\n  border-bottom: 0.0625rem solid #9ca3af;\r\n  border-bottom-left-radius: 4px;\r\n  border-bottom-right-radius: 4px;\r\n}\r\n\r\n.nav-tabs .nav-link {\r\n  margin-bottom: 0px;\r\n}\r\n\r\n.input-group .alert {\r\n  font-size: 0.6rem;\r\n}\r\n\r\n.divScrollBadges {\r\n  overflow: auto;\r\n  max-height: 100px;\r\n}\r\n\r\n.hourPart {\r\n  max-width: 66px;\r\n}\r\n\r\n.divAutomationsList {\r\n  overflow: auto;\r\n  max-height: 150px;\r\n}\r\n\r\n.divBacktestResults {\r\n  overflow: auto;\r\n  max-height: 350px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
